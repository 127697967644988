var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_c("v-icon", [_vm._v("arrow_back")])],
              1
            ),
            _c("span", { staticClass: "student-name" }, [
              _vm._v(_vm._s(_vm.studentName)),
            ]),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm.asses
              ? _c(
                  "v-btn",
                  {
                    staticClass: "mr-5",
                    attrs: { color: "primary", outlined: "" },
                    on: {
                      click: function ($event) {
                        _vm.asses = false
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                )
              : _c(
                  "v-btn",
                  {
                    staticClass: "mr-5",
                    attrs: { color: "primary", outlined: "" },
                    on: { click: _vm.download },
                  },
                  [_vm._v("Download")]
                ),
            _vm.asses
              ? _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", loading: _vm.saveProp },
                    on: { click: _vm.save },
                  },
                  [_vm._v("Save")]
                )
              : _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.asses = !_vm.asses
                      },
                    },
                  },
                  [_vm._v("Assesment")]
                ),
          ],
          1
        ),
      ]),
      _vm.loading
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm.docUrl
        ? _c("pdf-renderer", {
            staticClass: "mt-5",
            attrs: {
              url: _vm.docUrl,
              asses: _vm.asses,
              save: _vm.saveProp,
              "s3-key": _vm.s3Key,
            },
            on: { "save-done": _vm.onSave },
          })
        : _c("p", { staticClass: "text-center" }, [_vm._v("...")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }